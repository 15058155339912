<template>
  <modal v-model="modal" :loading="loading" max-width="400px" title="Донаты">
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind="attrs" v-on="on" />
    </template>
    <div class="modal-content">
      На данный момент проект абсолютно бесплатный и волонтерский, если понравилась платформа, можете пожертвовать сумму на чашку кофе программисту. <span>9099845712 Ибрагим Сбер</span>
    </div>
  </modal>
</template>

<script>
import Modal from "@/tags/Modal";

export default {
  name: "StatView",
  components: { Modal },
  data() {
    return {
      modal: false,
      loading: false
    };
  }
};
</script>

<style scoped>
.modal-content {
  text-align: center;
  padding: 20px;
}

.modal-content span {
  color: var(--v-primary-base) !important;
}
</style>
