<template>
  <div class="support-contact-item">
    <v-hover>
      <template #default="{ hover }">
        <v-img :aspect-ratio="1" :src="apiUri(item.avatar)">
          <v-fade-transition>
            <v-overlay v-if="hover && showManage" absolute class="video-overlay" color="#0000007f">
              <span class="support-contact-manage">
                <btn-icon color="white" icon="pencil" title="Редактировать" @click="$refs.form.open()"/>
                <btn-icon v-if="showDelete" :title="$t('btn.delete')" color="white" icon="delete" @click="$refs.delete.open()"/>
                <btn-icon v-if="!showDelete" :title="$t('btn.restore')" color="white" icon="delete-off" @click="$refs.restore.open()"/>
              </span>
            </v-overlay>
          </v-fade-transition>
        </v-img>
      </template>
    </v-hover>
    <div class="support-contact-info">
      <list-item :title="item.direction">
        <span class="font-weight-medium">{{ item.name }}</span>
      </list-item>
      <list-item title="Телефон">
        {{ formatPhone(item.phone) }}
        <template #action>
	<div class="action-buttons">
          <btn-icon :href="'tel:+' + item.phone" color="primary" icon="phone"/>
          <v-tooltip v-model="showPhoneTooltip" :open-on-focus="false" :open-on-hover="false" top>
            Скопировано
            <template #activator="{ on }">
              <btn-icon color="primary" icon="content-copy" @click="copyToClipboard(item.phone, 'phone')" v-on="on"/>
            </template>
          </v-tooltip>
	</div>
        </template>
      </list-item>
      <list-item title="Почта">
        {{ item.email }}
        <template #action>
	<div class="action-buttons">
          <btn-icon :href="'mailto:' + item.email" color="primary" icon="email-outline"/>
          <v-tooltip v-model="showEmailTooltip" :open-on-focus="false" :open-on-hover="false" top>
            Скопировано
            <template #activator="{ on }">
              <btn-icon color="primary" icon="content-copy" @click="copyToClipboard(item.email, 'email')" v-on="on"/>
            </template>
          </v-tooltip>
	</div>
        </template>
      </list-item>
    </div>
    <support-contact-form :id="item.id" ref="form" @save="$emit('update')"/>
    <delete :id="item.id" ref="delete" :name="item.name" @remove="$emit('update')"/>
    <restore :id="item.id" ref="restore" :name="item.name" @restore="$emit('update')"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BtnIcon from "@/tags/BtnIcon.vue";
import ListItem from "@/tags/ListItem.vue";
import { apiUri, formatPhone } from "@/helpers/stringHelpers";
import Restore from "@/views/supportContact/Restore.vue";
import Delete from "@/views/supportContact/Delete.vue";
import SupportContactForm from "@/views/supportContact/SupportContactForm.vue";

export default {
  name: "SupportContactItem",
  components: { SupportContactForm, Delete, Restore, ListItem, BtnIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPhoneTooltip: false,
      showEmailTooltip: false,
    };
  },
  computed: {
    ...mapGetters(['can']),
    showManage() {
      return this.can('support-contact.manage');
    },
    showDelete() {
      return !this.item.deleted_at;
    },
  },
  methods: {
    formatPhone,
    apiUri,
    copyToClipboard(value, type) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      if (type === 'phone') {
        this.showPhoneTooltip = true;
        setTimeout(() => this.showPhoneTooltip = false, 2000);
      } else if (type === 'email') {
        this.showEmailTooltip = true;
        setTimeout(() => this.showEmailTooltip = false, 2000);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.support-contact-item {
  border-radius: 12px;
  overflow: hidden;

  & .support-contact-info {
    border-radius: 12px;
    overflow: hidden;
    background-color: #FCF9F9;
    border: 1px solid rgba(50, 0, 0, 0.12);
    margin-top: -12px;
    position: relative;
    padding: 16px 0;
  }
}

.support-contact-manage {
  position: absolute;
  top: 8px;
  right: 8px;
  white-space: nowrap;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left {
  text-align: left;
}

.footer-right {
  display: flex;
  align-items: center;
}

.footer_copy {
  margin-right: 10px;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.v-list-item__action {
  display: flex;
  flex-direction: row !important; /* Переопределение flex-direction */
}

.v-tooltip {
  display: inline-block;
}

.btn-icon {
  margin-left: 10px;
}

.action-buttons {
  display: flex;
  align-items: center;
}
</style>
