<template>
  <v-footer v-if="$vuetify.breakpoint.mdAndUp" app color="grey lighten-2" elevation="2">
    <div class="footer-content d-flex justify-space-between align-center w-100">
      <div class="footer-left">
        2015 - {{ currentYear }} «Success Finance» ИНН 7717293829 ОГРН 1157746606930 БИК 044525225 ПАО Сбербанк Р/сч 40702810638000042893
        К/сч 30101810400000000225 mail: forclient@fin-success.biz
      </div>
      <div class="footer-right">
        <p class="footer_copy">Разработано в <a href="https://fin-success.biz/it-sf">IT SF</a></p>
        <a href="https://vk.com/successfinance" target="_blank"><img src="@/assets/vk.svg" alt="VK" class="social-icon"></a>
        <a href="https://ok.ru/successfinance" target="_blank"><img src="@/assets/ok.svg" alt="OK" class="social-icon"></a>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "FootBar",
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-left {
  text-align: left;
}
.footer-right {
  display: flex;
  align-items: center;
}
.footer_copy {
  margin-right: 10px;
  margin-bottom: 0px;
}
.social-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}
</style>
