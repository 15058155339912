<template>
  <modal v-model="modal" :loading="model.loading" :title="model.attr.name || 'Загружается...'" max-width="1200px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-responsive :aspect-ratio="16/9">
      <iframe v-if="modal && model.attr.embed" :src="model.attr.embed" allowfullscreen="allowfullscreen" class="player"/>
    </v-responsive>
    <div v-if="!model.loading" class="video-info">
      <div class="category">
        <v-chip class="font-weight-regular category-name" color="white">
          <span class="text-truncate">{{ model.attr?.category?.name }}</span>
        </v-chip>
        <v-spacer/>
        <v-icon v-if="!model.attr.public" class="ml-2" color="error">mdi-lock-open</v-icon>
        <v-icon v-if="model.attr.fixed" class="ml-2" color="warning">mdi-star</v-icon>
        <vote-btn :id="model.attr.isVoted" :count="model.attr.voteCount" :type_id="model.attr.id" :user_id="user.id" type="video" @vote="model.load()"/>
      </div>
      <div class="description">
        {{ model.attr.description }}
      </div>
    </div>
    <div v-else class="video-info">
      <v-skeleton-loader type="article"/>
    </div>
    <template #actions>
      <v-btn :disabled="disablePrev" color="primary" @click="setVideo(prevId)">
        Предыдущее <span v-if="longBtn">видео</span>
      </v-btn>
      <v-spacer/>
      <v-btn :disabled="disableNext" color="primary" @click="setVideo(nextId)">
        Следующее <span v-if="longBtn">видео</span>
      </v-btn>
    </template>
  </modal>
</template>

<script>
import {Video}                    from "@/models/Video";
import Modal                      from "../../tags/Modal";
import {mapGetters, mapMutations} from "vuex";
import {isNull}                   from "lodash";
import VoteBtn                    from "@/tags/VoteBtn.vue";

export default {
  name:       "VideoView",
  components: {VoteBtn, Modal},
 props: {
   id: {
     required: true
   },
   allIds: {
     type: Array,
     default: () => []
   },
   views: {
     type: Number,
     default: 0
   }
 },
  data() {
    return {
      modal: false,
      currentId: this.id,
      model: Video.get(this.id, {
        with: [
          'category',
          'nextCategoryVideoId',
          'prevCategoryVideoId',
          'voteCount',
          'isVoted',
        ]
      })
    }
  },
 computed: {
   ...mapGetters(['user']),
   currentIndex() {
     return this.allIds.indexOf(this.currentId); 
   },
   prevId() {
     return this.currentIndex > 0 ? this.allIds[this.currentIndex - 1] : 0;
   },
   nextId() {
     return this.currentIndex < this.allIds.length - 1 ? this.allIds[this.currentIndex + 1] : 0;
   },
   disablePrev() {
     return this.model.loading || this.currentIndex <= 0;
   },
   disableNext() {
     return this.model.loading || this.currentIndex >= this.allIds.length - 1;
   },
   longBtn() {
     return !this.$vuetify.breakpoint.mobile;
   }
 },
 watch: {
   modal(value) {
     if (value) {
       this.model.load();
       this.addView(this.currentId);
     }
     this.setKeepActive(value);
   },
   // Следим за изменением id в модели
   'model.attr.id': {
     handler(newId) {
       if (newId) {
         this.currentId = newId;
       }
     },
     immediate: true
   }
 },
methods: {
    ...mapMutations(['setKeepActive']),
    
    async addView(id) {
        try {
            await this.axios.post('video/add-view?id=' + id);
            // Emit событие для обновления счетчика в родительском компоненте
            this.$emit('update-views', id);
        } catch (error) {
            console.error('Error updating view count:', error);
        }
    },
    
    async setVideo(id) {
        this.model.attr.id = id;
        await this.model.load();
        await this.addView(id);
    }
},
}
</script>

<style scoped>
.player {
  width: 100%;
  height: 100%;
  border: none;
}

.video-info {
  position: relative;
  padding: 16px 16px 0;

  & .category {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    & .category-name {
      overflow: hidden;
      max-width: calc(100vw - 144px);
    }
  }

  & .description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
  }
}

.views {
    display: flex;
    align-items: center;
}

.views span {
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
}
</style>