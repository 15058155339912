<template>
  <v-card>
    <v-title title="Вопросы и ответы"/>
    <v-card-title>
      <span class="mr-5">Вопросы и ответы</span>
      <span v-if="can('faq.manage')">
      <faq-form @save="models.load()">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </faq-form>
      <toggle-deleted v-model="showDeleted" class="ml-2"/>
      </span>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
          hide-details
      />
    </v-card-title>
    <data-list
        :models="models"
    >

      <template v-slot:item="{ item }">
        <v-col cols="12">
          <div class="d-flex">
            <h2>{{ item.title }}</h2>
            <v-spacer/>
            <v-icon v-if="!isPublished(item)" color="accent">mdi-eye-off</v-icon>
          </div>
          <v-expand-transition>
            <div v-if="showDescription[item.id]" v-html="item.text"/>
          </v-expand-transition>
          <v-btn class="mt-2" x-small @click="toggleDescription(item.id)">{{ showDescription[item.id] ? 'Скрыть' : 'Показать' }}</v-btn>
          <div v-if="can('faq.manage')" class="text-right">
          <span v-if="!item.deleted_at" class="ml-2">
            <faq-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </faq-form>
          </span>
            <span v-if="!item.deleted_at" class="ml-2">
            <delete :id="item.id" :name="item.title" @remove="models.load()"/>
          </span>
            <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.title" @restore="models.load()"/>
          </span>
          </div>
        </v-col>
      </template>
    </data-list>

  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete                   from "@/views/faq/Delete";
import {mapActions, mapGetters} from "vuex";
import ToggleDeleted            from "@/views/faq/ToggleDeleted";
import Restore                  from "@/views/faq/Restore";
import FaqForm                  from "@/views/faq/FaqForm";
import VTitle                   from "@/tags/VTitle";
import BtnIcon                  from "@/tags/BtnIcon";
import DataList                 from "../../tags/DataList";


export default {
  name:       "Index",
  components: {DataList, FaqForm, BtnIcon, VTitle, Restore, ToggleDeleted, Delete},
  mounted() {
    this.updateNotify('faq');
  },
  beforeRouteUpdate() {
    this.updateNotify('faq');
  },
  data:    () => ({
    search: '',
    models: Search.create({
      uri:    'faq',
      fields: ['id', 'title', 'text', 'created_at', 'published_at', 'deleted_at'],
      params: {deleted: 0},
      filter: {or: [{title: {like: ''}}, {text: {like: ''}}]}
    }).load(),
    showDeleted:     0,
    showDescription: {}
  }),
  computed: {
    ...mapGetters(['can'])
  },
  watch:   {
    search(value) {
      this.models.config.filter.or[0].title.like = value || '';
      this.models.config.filter.or[1].text.like = value || '';
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods: {
    ...mapActions(['updateNotify']),
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      return this.$d(value * 1000, 'datetime');
    },
    toggleDescription(id) {
      this.showDescription = Object.assign({}, this.showDescription, {[id]: !this.showDescription[id]});
    },
    isPublished(item) {
      return item.published_at > 0 && item.published_at <= Math.floor(new Date().getTime() / 1000);
    },
  }
}
</script>

<style scoped>

</style>
